import * as React from "react";
import Layout from "../Layout/MasterLayout/MasterLayout";
import Button from "../components/Button/Button";
import CarrouselText from "../components/CarouselText/CarouselText";

import {
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCarousel,
	MDBCarouselInner,
	MDBCarouselItem,
	MDBMask,
} from "mdbreact";

import BannerCarousel from "../components/BannerCarousel/BannerCarousel";
import SEO from "../components/SEO/SEO";

export default function Index() {
	return (
		<Layout>
			<SEO title="Home" lang="pt-br" />
			<MDBMask className="d-flex justify-content-center align-items-center gradient">
				<MDBContainer fluid>
					<MDBRow>
						<MDBCol className="mx-0 px-0">
							<BannerCarousel bannerKey="E976F7B3-06CF-4575-9E9A-4C4C947A3A07" />
						</MDBCol>
					</MDBRow>

					<MDBRow>
						<MDBCol
							className="bgprimary-gradient"
							style={{ height: 10 }}
						></MDBCol>
					</MDBRow>
				</MDBContainer>
			</MDBMask>
			<MDBContainer>
				<MDBRow>
					<MDBCol className="pt-5">
						<h2 className="text-center text-md-left fgsecondary-gradient">
							matrículas e rematrículas
						</h2>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol className="pb-2 pt-4 py-md-4" md={6}>
						<Button
							type="comercial"
							to="/matriculas"
							text="Quero estudar na escola de aplicação feevale"
						/>
					</MDBCol>
					<MDBCol className="pb-4 py-md-4" md={6}>
						<Button
							type="comercial"
							to="/rematriculas"
							text="Quero fazer a rematrícula na escola de aplicação feevale"
						/>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<BannerCarousel bannerKey="11927b0f-2214-4c74-bf2b-3083347dbc7d" />
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<BannerCarousel bannerKey="478A950D-69E0-423D-8013-39862B43971C" />
					</MDBCol>
				</MDBRow>
				<MDBRow className="pb-5 py-md-5">
					<MDBCol className="pb-5 py-md-5 my-2" col={12} md={4}>
						<h2 className="fgprimary-gradient h1 text-center text-md-left font-italic">
							motivos para estudar na escola de aplicação feevale
						</h2>
					</MDBCol>
					<MDBCol col={12} md={8}>
						<CarrouselText
							objetos={[
								{
									title: "APRENDIZAGEM BASEADA EM FENÔMENOS E EXPERIÊNCIAS",
									description:
										"As nossas estratégias de aprendizagem estão fundamentadas na resolução de problemas a partir de fenômenos, estimulando a curiosidade e o pensamento criativo.",
								},
								{
									title: "COOPERAÇÃO NO LUGAR DE COMPETIÇÃO",
									description:
										"Nossa abordagem educacional respeita as diferenças e a diversidade, pois o nosso foco é trabalhar as relações humanas.",
								},
								{
									title: "APRENDIZAGEM INTERNACIONALIZADA",
									description:
										"Muito além de aprender outras línguas, o estudante tem contato com diferentes costumes. Através de intercâmbios presenciais e virtuais a experiência é multicultural.",
								},
								{
									title: "ESCOLA DO FUTURO",
									description:
										"Nossas metodologias contemporâneas de educação são inspiradas nas escolas mais inovadoras do mundo.",
								},
								{
									title: "O PROFESSOR É MENTOR",
									description:
										"Cada estudante da Escola tem um professor de referência, que acompanha mais de perto o processo de aprendizagem e atua como interface entre o estudante, o grupo de professores, família e demais profissionais. Tudo isso para evidenciar talentos, potencialidades e habilidades.",
								},
								{
									title: "A INFRAESTRUTURA DA UNIVERSIDADE SEMPRE DISPONÍVEL",
									description:
										"Toda a infraestrutura da Universidade Feevale é de acesso ao estudante da Escola de Aplicação.",
								},
								{
									title:
										"EXPERIÊNCIAS E VIVÊNCIAS SUSBTITUEM CONTEÚDO EXCESSIVO",
									description:
										"O estudante é protagonista do seu aprendizado através de metodologias que priorizam vivências e experiências, cujo conhecimento é construído de forma significativa.",
								},
								{
									title: "APRENDIZAGEM TECNOLOGICAMENTE INTEGRADA",
									description:
										"O estudante tem acesso e contato com ferramentas tecnológicas modernas durante sua trajetória escolar. Desde games a laboratórios de informática.",
								},
								{
									title: "MATERIAIS DIDÁTICOS AUTORAIS",
									description:
										"Nossos materiais são baseados no que há de melhor em produção científica e também nos fenômenos da atualidade. Uma vantagem significativa sobre os tradicionais livros didáticos.",
								},
								{
									title: "ORIENTAÇÃO PROFISSIONAL",
									description:
										"Possuímos projetos voltados à orientação profissional para cada estudante concluinte do Ensino Médio, alinhando projetos de vida em parceria com a Universidade Feevale.",
								},
								{
									title: "VIAGENS DE ESTUDOS",
									description:
										"Nossa metodologia contempla a vivência in loco de experiências propostas em saídas de estudos nacionais e internacionais de curta, média e longa duração. Isso expande a aprendizagem para além da sala de aula e do ambiente virtual.",
								},
							]}
							bgColor="bgprimary-gradient"
						/>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<h2 className="fgprimary-gradient text-center text-md-left font-italic">
							conheça mais sobre a escola
						</h2>
					</MDBCol>
				</MDBRow>
				{/* Vídeo novo da Escola. Sempre mantém o 16:9 */}
				<MDBRow>
					<MDBCol>
						<div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', background: '#fff', border:"3px solid", borderImage: 'linear-gradient(90deg, #adc90c 0%, #01afc0 100%) 1' }}>
						<iframe
							src="https://www.youtube.com/embed/KGe-5Czwu0g?si=uCJUM7r7WZRd0-lA"
							style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							referrerPolicy="strict-origin-when-cross-origin"
							allowFullScreen
							title="YouTube video player"
						></iframe>
						</div>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol col={12} md={6} className="py-2">
						<Button
							type="institucional"
							to="/a-escola/ambientes-de-aprendizagem"
							text="Ambientes de aprendizagem"
						/>
					</MDBCol>
					<MDBCol col={12} md={6} className="pb-2 py-md-2">
						<Button
							type="institucional"
							to="/acontece-na-escola"
							text="Acontece na escola"
						/>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol className="pb-4">
						<Button
							type="institucional"
							to="/a-escola/desafios-e-festivais"
							text="Desafios e festivais"
						/>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol className="pt-4">
						<h2 className="fgterciary-gradient text-center text-md-left font-italic">
							etapas de aprendizagem
						</h2>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol col={12} md={6} className="py-2">
						<Button
							type="ensino"
							to="/etapas-de-aprendizagem/educacao-infantil"
							text="Educação Infantil"
						/>
					</MDBCol>
					<MDBCol col={12} md={6} className="pb-2 py-md-2">
						<Button
							type="ensino"
							to="/etapas-de-aprendizagem/ensino-fundamental"
							text="Ensino Fundamental"
						/>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol col={12} md={6} className="pb-2 pb-md-4">
						<Button
							type="ensino"
							to="/etapas-de-aprendizagem/ensino-medio"
							text="Ensino Médio"
						/>
					</MDBCol>
					<MDBCol col={12} md={6} className="pb-4">
						<Button
							type="ensino"
							to="/etapas-de-aprendizagem/cursos-tecnicos"
							text="Cursos Técnicos"
						/>
					</MDBCol>
				</MDBRow>
				<hr/>
				<MDBRow>
				</MDBRow>
				<MDBRow>
					<MDBCol className="my-md-4 py-md-5 pb-4" col={12} md={4}>
						<p className="my-auto">
							Estamos no podcast{" "}
							<a
								href="https://open.spotify.com/show/2JWzVj5sS8xZsVERydR8sl?si=6ATTnjWDRFemL7jnNxtpNQ"
								target="_blank"
								rel="noreferrer"
							>
								Vida Disruptiva
							</a>{" "}
							do Grupo Sinos, dialogando sobre o futuro da educação. Confira:
						</p>
					</MDBCol>
					<MDBCol col={12} md={8}>
						<MDBCarousel
							activeItem={1}
							showControls={false}
							showIndicators={true}
							length={6}
						>
							<MDBCarouselInner>
								<MDBCarouselItem itemId={1}>
									<iframe
										src="https://open.spotify.com/embed-podcast/episode/07khToHFinrBDJ2IT2o6sS"
										height="245"
										className="w-100 border-0 "
										allowtransparency="true"
										allow="encrypted-media"
									></iframe>
								</MDBCarouselItem>
								<MDBCarouselItem itemId={2}>
									<iframe
										src="https://open.spotify.com/embed-podcast/episode/3hDNdhHvpwlRRQPR4O6DCX"
										height="245"
										className="w-100 border-0 "
										allowtransparency="true"
										allow="encrypted-media"
									></iframe>
								</MDBCarouselItem>
								<MDBCarouselItem itemId={3}>
									<iframe
										src="https://open.spotify.com/embed-podcast/episode/7bo79SuCRfKzFxUAzmshGF"
										height="245"
										className="w-100 border-0 "
										allowtransparency="true"
										allow="encrypted-media"
									></iframe>
								</MDBCarouselItem>
								<MDBCarouselItem itemId={4}>
									<iframe
										src="https://open.spotify.com/embed-podcast/episode/10X4nNDUB1r3DtSqFeGq9a"
										height="245"
										className="w-100 border-0 "
										allowtransparency="true"
										allow="encrypted-media"
									></iframe>
								</MDBCarouselItem>
								<MDBCarouselItem itemId={5}>
									<iframe
										src="https://open.spotify.com/embed-podcast/episode/78FVSkBFTgWvSJJH5wgDsU"
										height="245"
										className="w-100 border-0 "
										allowtransparency="true"
										allow="encrypted-media"
									></iframe>
								</MDBCarouselItem>
								<MDBCarouselItem itemId={6}>
									<iframe
										src="https://open.spotify.com/embed-podcast/episode/3jSo3AhLFG4j8WfC77FRpu"
										height="245"
										className="w-100 border-0 "
										allowtransparency="true"
										allow="encrypted-media"
									></iframe>
								</MDBCarouselItem>
							</MDBCarouselInner>
						</MDBCarousel>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</Layout>
	);
}
